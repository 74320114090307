exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-tyrvaya-index-jsx": () => import("./../../../src/pages/about-tyrvaya/index.jsx" /* webpackChunkName: "component---src-pages-about-tyrvaya-index-jsx" */),
  "component---src-pages-dosing-and-administration-index-jsx": () => import("./../../../src/pages/dosing-and-administration/index.jsx" /* webpackChunkName: "component---src-pages-dosing-and-administration-index-jsx" */),
  "component---src-pages-faqs-index-jsx": () => import("./../../../src/pages/faqs/index.jsx" /* webpackChunkName: "component---src-pages-faqs-index-jsx" */),
  "component---src-pages-hcp-resources-index-jsx": () => import("./../../../src/pages/hcp-resources/index.jsx" /* webpackChunkName: "component---src-pages-hcp-resources-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-manufacturer-terms-and-conditions-index-jsx": () => import("./../../../src/pages/manufacturer-terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-manufacturer-terms-and-conditions-index-jsx" */),
  "component---src-pages-patient-resources-index-jsx": () => import("./../../../src/pages/patient-resources/index.jsx" /* webpackChunkName: "component---src-pages-patient-resources-index-jsx" */),
  "component---src-pages-real-tears-index-jsx": () => import("./../../../src/pages/real-tears/index.jsx" /* webpackChunkName: "component---src-pages-real-tears-index-jsx" */),
  "component---src-pages-safety-profile-index-jsx": () => import("./../../../src/pages/safety-profile/index.jsx" /* webpackChunkName: "component---src-pages-safety-profile-index-jsx" */),
  "component---src-pages-savings-and-support-index-jsx": () => import("./../../../src/pages/savings-and-support/index.jsx" /* webpackChunkName: "component---src-pages-savings-and-support-index-jsx" */),
  "component---src-pages-savings-terms-and-conditions-index-jsx": () => import("./../../../src/pages/savings-terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-savings-terms-and-conditions-index-jsx" */),
  "component---src-pages-signs-data-index-jsx": () => import("./../../../src/pages/signs-data/index.jsx" /* webpackChunkName: "component---src-pages-signs-data-index-jsx" */),
  "component---src-pages-symptoms-data-index-jsx": () => import("./../../../src/pages/symptoms-data/index.jsx" /* webpackChunkName: "component---src-pages-symptoms-data-index-jsx" */),
  "component---src-pages-transcript-administration-index-jsx": () => import("./../../../src/pages/transcript/administration/index.jsx" /* webpackChunkName: "component---src-pages-transcript-administration-index-jsx" */),
  "component---src-pages-transcript-clinical-data-index-jsx": () => import("./../../../src/pages/transcript/clinical-data/index.jsx" /* webpackChunkName: "component---src-pages-transcript-clinical-data-index-jsx" */),
  "component---src-pages-transcript-crystals-story-index-jsx": () => import("./../../../src/pages/transcript/crystals-story/index.jsx" /* webpackChunkName: "component---src-pages-transcript-crystals-story-index-jsx" */),
  "component---src-pages-transcript-deep-dive-index-jsx": () => import("./../../../src/pages/transcript/deep-dive/index.jsx" /* webpackChunkName: "component---src-pages-transcript-deep-dive-index-jsx" */),
  "component---src-pages-transcript-dry-eye-treatment-index-jsx": () => import("./../../../src/pages/transcript/dry-eye-treatment/index.jsx" /* webpackChunkName: "component---src-pages-transcript-dry-eye-treatment-index-jsx" */),
  "component---src-pages-transcript-eileens-story-index-jsx": () => import("./../../../src/pages/transcript/eileens-story/index.jsx" /* webpackChunkName: "component---src-pages-transcript-eileens-story-index-jsx" */),
  "component---src-pages-transcript-how-to-use-index-jsx": () => import("./../../../src/pages/transcript/how-to-use/index.jsx" /* webpackChunkName: "component---src-pages-transcript-how-to-use-index-jsx" */),
  "component---src-pages-transcript-how-tyrvaya-works-index-jsx": () => import("./../../../src/pages/transcript/how-tyrvaya-works/index.jsx" /* webpackChunkName: "component---src-pages-transcript-how-tyrvaya-works-index-jsx" */),
  "component---src-pages-transcript-innovative-platform-index-jsx": () => import("./../../../src/pages/transcript/innovative-platform/index.jsx" /* webpackChunkName: "component---src-pages-transcript-innovative-platform-index-jsx" */),
  "component---src-pages-transcript-mechanism-of-action-index-jsx": () => import("./../../../src/pages/transcript/mechanism-of-action/index.jsx" /* webpackChunkName: "component---src-pages-transcript-mechanism-of-action-index-jsx" */),
  "component---src-pages-transcript-michaels-story-index-jsx": () => import("./../../../src/pages/transcript/michaels-story/index.jsx" /* webpackChunkName: "component---src-pages-transcript-michaels-story-index-jsx" */),
  "component---src-pages-transcript-nasal-spray-index-jsx": () => import("./../../../src/pages/transcript/nasal-spray/index.jsx" /* webpackChunkName: "component---src-pages-transcript-nasal-spray-index-jsx" */)
}

