import { debounce } from '../utils/helper';

// Scroll Depth START
let distanceScrolled = -1;
let distanceThreshold = [
    { threshold: 0, value: 'Baseline', sent: false },
    { threshold: 25, value: '25%', sent: false },
    { threshold: 50, value: '50%', sent: false },
    { threshold: 75, value: '75%', sent: false },
    { threshold: 100, value: '100%', sent: false },
];

const scrollDepth = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let progress = Math.round(
        (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100
    );

    if (isNaN(progress)) progress = 0;

    if (progress > distanceScrolled) {
        distanceScrolled = progress;

        for (let i = 0; i < distanceThreshold.length; i++) {
            const status = distanceThreshold[i].sent;
            const threshold = distanceThreshold[i].threshold;
            const value = distanceThreshold[i].value;

            if (!status && progress >= threshold) {
                if ('dataLayer' in window) {
                    window.dataLayer.push({
                        event: 'scrollDepth',
                        scrollDistance: value,
                    });
                    distanceThreshold[i].sent = true;
                }
            }
        }
    }
};

const scrollListener = debounce(scrollDepth, 18);
// Scroll Depth END

// VIDEO START
const videoPercentages = [
    { percentage: '10%', value: 0.1, sent: false },
    { percentage: '20%', value: 0.2, sent: false },
    { percentage: '30%', value: 0.3, sent: false },
    { percentage: '40%', value: 0.4, sent: false },
    { percentage: '50%', value: 0.5, sent: false },
    { percentage: '60%', value: 0.6, sent: false },
    { percentage: '70%', value: 0.7, sent: false },
    { percentage: '80%', value: 0.8, sent: false },
    { percentage: '90%', value: 0.9, sent: false },
    { percentage: '100%', value: 1, sent: false },
];

let videoSeconds = [];

const videoTime = (video, title) => {
    // Send video event after time lapsed, every 10% interval
    const currentPercentage = video.percent;
    const unsentIndex = videoPercentages.findIndex((percentageValue) => {
        const currentPercentageValue = (Math.floor(currentPercentage * 10) / 10).toFixed(
            1
        );
        return (
            !percentageValue.sent &&
            Number(currentPercentageValue) === percentageValue.value
        );
    });
    if (unsentIndex >= 0) {
        window.dataLayer.push({
            event: 'videoPercentage',
            videoCategory: 'video-interval-percentage',
            videoFile: title,
            videoPercentage: videoPercentages[unsentIndex].percentage,
        });
        videoPercentages[unsentIndex].sent = true;
    }

    // Send video event after time lapsed, every 10s interval
    for (let i = 0; i < video.duration; i += 10) {
        if (!videoSeconds.find((threshold) => threshold.value === i)) {
            videoSeconds.push({ value: i, sent: false });
        }
    }
    const secondsIndex = videoSeconds.findIndex((threshold) => {
        const currentSeconds = Math.floor(video.seconds / 10) * 10;
        return !threshold.sent && Number(currentSeconds) === threshold.value;
    });
    if (secondsIndex >= 0) {
        window.dataLayer.push({
            event: 'videoSeconds',
            videoCategory: 'video-interval-seconds',
            videoFile: title,
            videoSeconds: videoSeconds[secondsIndex].value,
        });
        videoSeconds[secondsIndex].sent = true;
    }
};

const videoTimeListener = debounce(videoTime, 100);

const videoPlayListener = (v) => {
    if (typeof window !== 'undefined') {
        window.dataLayer.push({
            event: 'videoStart',
            videoFile: v,
            videoStatus: 'Start',
        });
    }
};

const videoEndListener = (v) => {
    if (typeof window !== 'undefined') {
        window.dataLayer.push({
            event: 'videoEnd',
            videoFile: v,
            videoStatus: 'End',
        });
    }
};
// VIDEO END

let timerReady = null;
let counterReady = 0;
let timer15sec = null;
let counter15sec = 0;

const GTM = {
    handleRoute: () => {
        const setTimer15sec = () => {
            if (!timer15sec) {
                timer15sec = setInterval(() => {
                    counter15sec += 15;
                    window.dataLayer.push({
                        event: 'timer15seconds',
                        timer15secondsValue: counter15sec,
                    });

                    if (counter15sec >= 120) {
                        clearInterval(timer15sec);
                        timer15sec = null;
                        counter15sec = 0;
                    }
                }, 15000);
            }
        };

        const clearTimerReady = () => {
            clearInterval(timerReady);
            timerReady = null;
            counterReady = 0;
        };

        timerReady = setInterval(() => {
            if ('dataLayer' in window && 'google_tag_manager' in window) {
                clearTimerReady();
                setTimer15sec();
            } else if (counterReady === 60) {
                clearTimerReady();
            }

            counterReady++;
        }, 500);
    },
    init: () => {
        const interval = setInterval(() => {
            if ('dataLayer' in window && 'google_tag_manager' in window) {
                window.dataLayer.push({ event: 'gatsby-route-change' });
                clearInterval(interval);
            }
        }, 100);
        scrollDepth();
        document.addEventListener('scroll', scrollListener);
    },
    initVideo: (player, title) => {
        if ('dataLayer' in window) {
            player.on('play', () => {
                videoPlayListener(title);
            });

            player.on('ended', () => {
                videoEndListener(title);
            });

            player.on('timeupdate', (e) => {
                videoTimeListener(e, title);
            });
        }
    },
    inputChange: (formName, fieldName) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'inputChange',
                formName,
                fieldName,
            });
        }
    },
    handleFormErrors: (formName, fieldName) => {
        let fieldErrorName;
        if (fieldName === 'emailAddress') {
            fieldErrorName = 'Please enter a valid email address.';
        } else if (fieldName === 'officeZip') {
            fieldErrorName = 'Please enter a valid zip code.';
        } else if (fieldName === 'occupationSelect') {
            fieldErrorName = 'Please select an item in the list.';
        } else if (fieldName === 'npiNumber') {
            fieldErrorName = 'Please enter a valid NPI number.';
        } else {
            fieldErrorName = 'Please fill out this field.';
        }

        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formFieldError',
                formName,
                fieldName,
                fieldErrorName,
            });
        }
    },
    modal: (action, label) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'modalChange',
                modalAction: action,
                exitUrl: label,
            });
        }
    },
    handleFormSubmit: (formName) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formSubmit',
                formName,
            });
        }
    },
    reset: () => {
        videoPercentages.forEach((threshold) => (threshold.sent = false));
        videoSeconds = [];
        distanceScrolled = -1;
        distanceThreshold.forEach((threshold) => (threshold.sent = false));
    },
};

export default GTM;
